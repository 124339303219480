"use client";
import React from "react";
import { isDateInPast } from "@/lib/date";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import { getCourseRunStartText } from "@/lib/course/utils/getCourseRunTilePrimaryText";
import { getCourseRunEndText } from "@/lib/course/utils/getCourseRunTitleSecondaryText";
import { CourseEnrollProps } from "@/components/CourseContent/EnrollButton/types";
import useCourseEnrollment from "../hooks/useCourseEnrollment";
import EnrollLink from "../CourseEnrollButtons/EnrollLink";
import AuditLink from "../CourseEnrollButtons/AuditLink";

function CourseEnrollInline({
  buttonClass = "",
  className = "",
  courseRun,
  course,
  showEndDate = true,
  showMobileAuditLink = true,
  isStickyCta = false,
  name = "",
  location = "",
  showStartDate = true,
}: CourseEnrollProps) {
  const {
    isUserEnrolledInRun,
    isCourseEnrollable,
    isPaidLeadLoading,
    isCourseArchived,
  } = useCourseEnrollment({
    courseRun,
    course,
  });

  if (isPaidLeadLoading) {
    return (
      <div className="flex flex-row gap-3 items-center bg-putty-100 p-4 rounded-xl border-putty-400 border">
        <Skeleton role="status" className="h-11 w-56 rounded-none" />
      </div>
    );
  }
  //
  const enrollmentEnded = courseRun.enrollmentEnd
    ? isDateInPast(courseRun.enrollmentEnd)
    : false;

  const auditLinkClassName = isStickyCta ? "text-white max-md:p-0" : "";
  const fontColor = isStickyCta ? "text-white" : "";

  return (
    <>
      <div
        className={cn(
          "flex flex-col md:flex-row gap-4 sm:gap-2 items-center justify-between bg-putty-100 p-4 rounded-xl border-putty-400 border w-auto xl:w-[calc(50%-12px)] [&:only-child]:w-auto",
          className || "",
        )}
      >
        {isCourseArchived || !isCourseEnrollable ? (
          <span className={`font-bold text-base ${fontColor}`}>
            This course is archived
          </span>
        ) : (
          <div className={`text-center md:text-left ${fontColor}`}>
            <div
              className={`${showStartDate ? "block" : "hidden md:block"} font-bold ${!isStickyCta ? "md:mb-1" : ""}`}
            >
              {getCourseRunStartText(courseRun)}
            </div>
            {showEndDate ? (
              <div className="md:mt-1">{getCourseRunEndText(courseRun)}</div>
            ) : null}
          </div>
        )}

        {isCourseEnrollable ? (
          <div
            className={`flex flex-col md:flex-row gap-2 sm:gap-3 items-center ${isStickyCta ? "w-full md:w-auto md:pl-3" : ""}`}
          >
            <EnrollLink
              buttonClass={buttonClass}
              courseRun={courseRun}
              course={course}
              name={name}
              location={location}
            />
            <div
              className={`${showMobileAuditLink ? "block" : "hidden md:block"} `}
            >
              <AuditLink
                courseRun={courseRun}
                course={course}
                className={auditLinkClassName}
                name={name}
                location={location}
                variant={isStickyCta ? "anchor" : "button"}
              />
            </div>
          </div>
        ) : !isUserEnrolledInRun && enrollmentEnded ? (
          <span className={fontColor}>Future dates to be announced</span>
        ) : null}
      </div>
    </>
  );
}

export default CourseEnrollInline;
