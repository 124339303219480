import { CourseRun } from "@/lib/courseRun/types";

export function sortCourseRuns(courseRuns?: CourseRun[]): CourseRun[] {
  if (!courseRuns) return [];

  return courseRuns.sort((crA, crB) => {
    if (!crA.start && !crB.start) return 0;
    if (!crA.start) return 1;
    if (!crB.start) return -1;

    const startComparison =
      new Date(crA.start).getTime() - new Date(crB.start).getTime();

    // If start dates are equal, sort by end date
    if (startComparison === 0) {
      if (crA.end && crB.end) {
        return new Date(crA.end).getTime() - new Date(crB.end).getTime();
      }
      if (crA.end) return -1;
      if (crB.end) return 1;
      return 0;
    }

    return startComparison;
  });
}
