"use client";
import React, { useState } from "react";
import parse from "html-react-parser";
import { ProcessedCourse, Video } from "@/lib/course/types";
import CourseSnapshot from "@/components/CourseContent/CourseSnapshot";
import Sponsors from "../Sponsors";
import Collaborators from "../Collaborators";
import { Button } from "@/components/ui/button";
import Icon from "@/components/icons";
import Image from "next/image";
import YouTubeVideoPlayer from "@/components/VideoPlayer/components/YoutubeVideoPlayer";
import { MonarchResolverLegacy } from "@/components/ExecEdATFTest/MonarchResolverLegacy";

type Props = {
  courseData: ProcessedCourse;
  paymentOption: string | null;
  video?: Video;
  image?: string;
  title?: string;
};

export default function CourseDescription({
  courseData,
  paymentOption,
  video,
  image,
  title,
}: Props) {
  const [isVideoOpen, setIsVideoOpen] = useState<boolean>(false);
  const sponsors = courseData.sponsors?.length ? courseData.sponsors : [];
  const collaborators = courseData.collaborators?.length
    ? courseData.collaborators
    : [];
  return (
    <div>
      <MonarchResolverLegacy
        TestComponent={<></>}
        Control={
          <CourseSnapshot
            course={courseData}
            paymentOption={paymentOption}
            hasDateColumn
            className="block py-2 md:hidden"
          />
        }
        Loader={<></>}
      />
      <div>
        {video && video.src && (
          <div className="relative block md:hidden mb-4">
            {!isVideoOpen ? (
              <div>
                <Image
                  src={image || ""}
                  alt={title || ""}
                  layout="responsive"
                  width={345}
                  height={190}
                  className="w-full h-auto object-cover"
                />
                <Button
                  onClick={() => setIsVideoOpen(true)}
                  variant="inversePrimary"
                  className="sm:left-[50%] bg-white text-base hover:bg-primary hover:border-transparent hover:text-white px-4 py-2"
                  radius="md"
                >
                  <span className="mr-2 -ml-1">
                    <Icon type="circle-play" />
                  </span>
                  Play Video
                </Button>
              </div>
            ) : (
              <div className="min-h-[190px] mb-4">
                <YouTubeVideoPlayer
                  title={title || ""}
                  videoClassName="w-full h-full"
                  videoUrl={video.src}
                />
              </div>
            )}
          </div>
        )}
        <div className="prose" style={{ wordBreak: "keep-all" }}>
          {parse(courseData.productFullDescription)}
        </div>
        <div className="pt-2 hidden md:block">
          {sponsors?.length > 0 ? (
            <Sponsors
              sponsors={sponsors}
              containerClassName="pt-2"
              titleClassName="text-3xl mt-0 mb-4"
            />
          ) : null}
          {collaborators?.length > 0 ? (
            <Collaborators
              collaborators={collaborators}
              containerClassName="pt-2"
              titleClassName="text-3xl mt-0 mb-2"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
