"use client";
import { CourseRun } from "@/lib/courseRun/types";
import { ProcessedCourse } from "@/lib/course/types";
import CourseEnroll from "../CourseEnroll";
import StickyCTAWrapper from "@/components/StickyCTAWrapper";

type Props = {
  courseRun: CourseRun;
  course: ProcessedCourse;
  forceShow?: boolean;
};
export default function StickyCTA({
  courseRun,
  course,
  forceShow = false,
}: Props) {
  if (Object.keys(courseRun).length === 0) return null;

  return (
    <StickyCTAWrapper forceShow={forceShow}>
      <CourseEnroll
        course={course}
        courseRun={courseRun}
        showEndDate={false}
        showStartDate={false}
        showMobileAuditLink={false}
        className="p-4 pb-6 justify-center items-center gap-2 md:gap-4 md:h-[88px] bg-primary border-none rounded-none"
        name="sticky-cta"
        location="sticky-banner"
        isStickyCta
      />
    </StickyCTAWrapper>
  );
}
