import { CourseRun } from "@/lib/courseRun/types";
import getCourseRunStartDate from "./getCourseRunStartDate";
import {
  getI18nDateContextualString,
  getI18nDateString,
  getI18nDayMonthString,
  isDateInCurrentYear,
  isEndingWithinThisYear,
} from "@/lib/date";
import { COURSE_PACING_SELF } from "./constants";

const getCourseRunTilePrimaryText = (courseRun: CourseRun) => {
  const { isEnrollable, pacingType, start, end } = courseRun;
  const startDate = getCourseRunStartDate(courseRun);
  if (startDate) {
    // enrollable, not yet started
    if (start && isEnrollable && new Date(start) > new Date()) {
      const formattedStartDate = isEndingWithinThisYear(new Date(startDate))
        ? getI18nDayMonthString(startDate)
        : getI18nDateString(startDate);

      return `Starts ${formattedStartDate}`;
    }
    // enrollable, started, not ended
    if (isEnrollable && (!end || new Date(end) > new Date())) {
      // self-paced
      if (pacingType === COURSE_PACING_SELF) {
        return `Starts ${getI18nDayMonthString(new Date(startDate))}`;
      }
      // instructor-paced
      return `Started ${getI18nDayMonthString(new Date(startDate))}`;
    }
  }
};

export const getCourseRunStartText = (
  courseRun: CourseRun,
  isDateOnly: boolean = false,
) => {
  const { isEnrollable, pacingType, start, end } = courseRun;
  const startDate = getCourseRunStartDate(courseRun);
  if (startDate) {
    // enrollable, not yet started
    if (start && isEnrollable && new Date(start) > new Date()) {
      const formattedStartDate = getI18nDateContextualString(
        new Date(startDate),
      );
      return `${!isDateOnly ? "Starts " : ""}${formattedStartDate}`;
    }
    // enrollable, started, not ended
    if (isEnrollable && (!end || new Date(end) > new Date())) {
      // self-paced
      if (pacingType === COURSE_PACING_SELF) {
        return `${!isDateOnly ? "Starts " : ""}${getI18nDateContextualString(new Date(startDate))}`;
      }
      // instructor-paced
      return `${!isDateOnly ? "Started " : ""}${getI18nDateContextualString(new Date(startDate))}`;
    }
  }
};

export default getCourseRunTilePrimaryText;
