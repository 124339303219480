import { CourseEnrollButtonProps } from "@/components/CourseContent/EnrollButton/types";
import makeEvent from "@/hooks/eventing/makeEvent";
import { ConversionTracked } from "@/hooks/eventing/types/core.conversions.ConversionTracked.v2";
import { EVENTS } from "@/hooks/eventing/types/events";
import { ProcessedCourse } from "@/lib/course/types";
import { CourseRun } from "@/lib/courseRun/types";
import {
  getCorrelationID,
  getBrandString,
  getVariantCategoryFromUrl,
  hyphenateForTagular,
} from "@/utils/tagular/helpers";

const triggerConversionEvent =
  ({
    customName = "enroll",
    courseRun,
    isUserEntitled,
    course,
    action = "Started",
  }: {
    customName: CourseEnrollButtonProps["conversionType"] | undefined;
    courseRun: CourseRun;
    isUserEntitled?: boolean;
    course: ProcessedCourse;
    action?:
      | "Completed"
      | "Received"
      | "Approved"
      | "Declined"
      | "Cancelled"
      | "Started"
      | "Activated"
      | "Viewed"
      | "Opened"
      | "Submitted";
  }) =>
  () => {
    const { variant, category } = getVariantCategoryFromUrl(
      courseRun.marketingUrl,
    );

    const conversionEvent: ConversionTracked = {
      correlation: { id: getCorrelationID() },
      metadata: {
        customName,
        action: action,
        category: "Enrollment",
        timestamp: new Date().toISOString(),
        productList: [
          {
            brand: getBrandString(course),
            category: category,
            variant: variant,
            name: courseRun.title || "unknown",
          },
        ],
      },
    };
    if (isUserEntitled) {
      conversionEvent.revenue = { actualRevenue: { amount: 0 } };
    }
    const event = makeEvent<ConversionTracked, EVENTS>(conversionEvent);
    event<ConversionTracked>(EVENTS.ConversionTracked)();
  };

export default triggerConversionEvent;
