"use client";
import { ProcessedCourse } from "@/lib/course/types";
import CourseEnroll from "../CourseEnroll";
import { sortCourseRuns } from "@/components/CourseContent/ContentRefresh/CourseRunSelector/utils/sortCourseRuns";

type Props = {
  course: ProcessedCourse;
  name?: string;
  location?: string;
};

export default function CourseRunSelector({
  course,
  name = "",
  location = "",
}: Readonly<Props>) {
  const sortedCourseRuns = sortCourseRuns(course.activeCourseRuns);

  const mainCourseRun = sortedCourseRuns?.at(0);

  const multipleRunsAvailable = (sortedCourseRuns || []).length > 1;

  let seeMoreDatesHandler = false;
  if (multipleRunsAvailable) {
    seeMoreDatesHandler = true;
  }

  return (
    <div id="enroll">
      {mainCourseRun ? (
        <CourseEnroll
          key={mainCourseRun.id}
          courseRun={mainCourseRun}
          course={course}
          data-testid={`course-run-selector-run-${mainCourseRun.id}`}
          seeMoreDatesHandler={seeMoreDatesHandler}
          variant="box"
          name={name}
          location={location}
        />
      ) : null}
    </div>
  );
}
