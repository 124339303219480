import Image from "next/image";
import Link from "next/link";
import parse from "html-react-parser";
import { toggleEmailOptIn as trackToggleEmailOptIn } from "@/lib/segment";

import EmailOptIn from "@/components/EmailOptIn/EmailOptIn";

import { PartialPartner } from "@/lib/partner/types";
import useUserEnrollments from "../../CourseContext/hooks/useUserEnrollments";
import CourseRating from "../../CourseRating";
import { ProcessedCourse } from "@/lib/course/types";
import CourseRunSelector from "../CourseRunSelector";
import { hideEmailOptInForCourse } from "../../CourseRunSelector/constants";
import isCourseArchived from "@/lib/course/utils/isCourseArchived";
import useEmailOptIn from "../../CourseContext/hooks/useEmailOptIn";
import { partnerImageDimensions } from "@/constants/hero/imageDimensions";
import ExpandableTextByLines from "../../Expand/ExpandableTextByLines";

export interface ICoursePageHeroProps {
  title: string;
  course: ProcessedCourse;
}

export default function NewCourseHeader({
  title,
  course,
}: ICoursePageHeroProps) {
  const { emailOptIn, setEmailOptIn } = useEmailOptIn();
  const { isUserEnrolled } = useUserEnrollments();
  const { activeCourseRun } = course;
  const partners = course.partners.length ? course.partners : [];
  const isArchived = activeCourseRun ? isCourseArchived(activeCourseRun) : true;
  const description = course.productShortDescription;
  const id = course.productUuid;
  const productName = course.productName;
  const courseReview = course.ratings;

  const toggleEmailOptIn = () => {
    const optIn = !emailOptIn;
    setEmailOptIn(optIn);
    if (activeCourseRun?.key) {
      trackToggleEmailOptIn({
        courseRunKey: activeCourseRun?.key,
        optIn: optIn,
      });
    }
  };

  const courseDescription = parse(description);

  return (
    <div className="bg-white rounded-xl py-6 px-4 sm:p-10 md:flex md:items-start md:justify-between">
      <div className="flex flex-col gap-5 w-full">
        <div className="flex border-putty-400 border-b pb-4 w-full gap-4">
          <div className="flex flex-col">
            <div className="flex justify-center sm:justify-start">
              {partners.map((partner: PartialPartner) => {
                return partner.showOnSchoolsAndPartnersPage ? (
                  <Link
                    className="d-inline-block"
                    key={partner.key}
                    href={`/school/${partner.slug}`}
                  >
                    <Image
                      src={partner.logoUrl || ""}
                      width={partnerImageDimensions.width}
                      height={partnerImageDimensions.height}
                      alt={title || ""}
                      title={title || ""}
                      key={partner.key}
                      className="max-w-[165px] md:max-w-full"
                    />
                  </Link>
                ) : (
                  <Image
                    src={partner.logoUrl || ""}
                    width={partnerImageDimensions.width}
                    height={partnerImageDimensions.height}
                    alt={title || ""}
                    title={title || ""}
                    key={partner.key}
                    className="max-w-[165px] md:max-w-full"
                  />
                );
              })}
            </div>
            <div className="flex flex-col md:flex-row gap-5">
              <div className="flex flex-col gap-5 flex-grow">
                <h1
                  className="m-0 p-0 tracking-tight"
                  style={{ fontWeight: 900 }}
                >
                  {title}
                </h1>
                {courseReview && (
                  <CourseRating {...courseReview} variant="solid" />
                )}
                <ExpandableTextByLines
                  text={courseDescription}
                  className="md:[&_p]:text-lg [&_p]:m-0 w-full"
                  maxLines={2}
                />
              </div>
              <div className="flex w-auto min-w-[300px]">
                <div className="flex gap-4 flex-col w-full">
                  <CourseRunSelector
                    course={course}
                    name="hero-cta"
                    location="hero"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden md:flex md:flex-col">
          {!hideEmailOptInForCourse.includes(id) &&
            !isUserEnrolled &&
            !isArchived &&
            activeCourseRun?.isEnrollable && (
              <EmailOptIn
                optIn={emailOptIn}
                partners={partners}
                title={productName}
                toggleEmailOptIn={toggleEmailOptIn}
                variant="secondary"
              />
            )}
        </div>
      </div>
    </div>
  );
}
