"use client";
import React from "react";
import { cn } from "@/lib/utils";
import { AuditLinkVaraintProps } from "../types";
import Icon from "@/components/icons";

function AuditLinkIcon({
  auditCourseLabel,
  isDisabled,
  onEnroll,
  className = "",
}: AuditLinkVaraintProps) {
  return (
    <div
      className={cn("flex flex-row gap-1 justify-end items-center", className)}
    >
      <Icon type="todo-list" />
      <button
        className="underline underline-offset-2"
        onClick={onEnroll}
        disabled={isDisabled}
      >
        {auditCourseLabel}
      </button>
    </div>
  );
}

export default AuditLinkIcon;
