"use client";
import CourseHeader from "@/components/CourseContent/ContentRefresh/CourseHeader";
import InstructorList from "@/components/CourseContent/ContentRefresh/InstructorList";
import FullProgramCTA from "@/components/CourseContent/FullProgramCTA";
import { ProcessedCourse } from "@/lib/course/types";
import { Program } from "@/lib/program/types";
import Curriculum from "@/components/CourseContent/Curriculum";
import LearnerTestimonials from "@/components/CourseContent/LearnerTestimonials";
import WhatYoullLearn from "@/components/CourseContent/ContentRefresh/WhatYoullLearn";
import CourseAbout from "@/components/CourseContent/ContentOverview";
import EnterpriseCTA from "@/components/CourseContent/ContentRefresh/EnterpriseCTA";
import CourseAwards from "@/components/CourseContent/Awards";
import isCourseArchived from "@/lib/course/utils/isCourseArchived";
import ACERecommendationCard from "@/components/CourseContent/ACERecommendation";
import MidPageCTA from "@/components/CourseContent/ContentRefresh/MidPageCTA";
import SocialShare from "@/components/CourseContent/ContentRefresh/SocialShare";
import FactoidBar from "@/components/CourseContent/ContentRefresh/FactoidBar";
import StickyCTA from "@/components/CourseContent/ContentRefresh/StickyCTA";
import FAQs from "@/components/CourseContent/ContentRefresh/FAQs";
import useClientEnrollments from "@/lib/partner-api/useClientEnrollments";
import useClientEntitlements from "@/lib/partner-api/useClientEntitlements";
import useUserEnrollments from "@/components/CourseContent/CourseContext/hooks/useUserEnrollments";
import useUserEntitlements from "@/components/CourseContent/CourseContext/hooks/useUserEntitlements";
import { useEffect } from "react";
import isUserEnrolled from "@/lib/course/utils/isUserEnrolled";
import MoreAboutCourse from "@/components/CourseContent/ContentRefresh/MoreAboutCourse";
import ISO6391 from "iso-639-1";
import {
  fullWidthContainer,
  mainContent,
  maxWidthContainer,
} from "@/components/CourseContent/styles/constants";
import styles from "@/components/CourseContent/styles/contentHero.module.css";
import useLeadWithPaid from "@/monarch/rules/leadWithPaid/useLeadWithPaid";
import WaysToTakeCourse from "@/components/CourseContent/ContentRefresh/WaysToTakeCourse";
import CertificateWaysToTakeCourse from "@/components/CourseContent/ContentRefresh/Certificate/CertificateWaysToTakeCourse";
import EnrollLink from "@/components/CourseContent/ContentRefresh/CourseEnroll/CourseEnrollButtons/EnrollLink";
import AuditLink from "@/components/CourseContent/ContentRefresh/CourseEnroll/CourseEnrollButtons/AuditLink";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import useCertificateEmphasisRedesign from "@/monarch/rules/certificateEmphasisRedesign/useCertificateEmphasisRedesign";
import CertificateEmphasisTabs from "@/components/CourseContent/ContentRefresh/Certificate/CertificateEmphasisTabs";
import CertificateEmphasis from "@/components/CourseContent/ContentRefresh/Certificate/CertificateEmpashis";
import { RedesignedTrackComparison } from "@/components/CourseContent/ContentRefresh/Certificate/constants/enums";
import useSingleCourseDetailPageRedesign from "@/monarch/rules/singleCourseDetailPageRedesign/useSingleCourseDetailPageRedesign";

type Props = {
  course: ProcessedCourse;
  program?: Program | null;
  programPacingType?: string;
  programDuration?: string;
  hoursPerWeekFormatted?: string;
  paymentOption: "free" | "upgradable" | "paid" | null;
  showCourseModes: boolean;
};

export default function NewOpenCourseContent({
  course,
  showCourseModes,
  paymentOption,
}: Props) {
  //fetch enrollment data
  const { enrollments: userEnrollments } = useClientEnrollments();

  //fetch entitlement data
  const { entitlements: userEntitlements } = useClientEntitlements();

  const { setIsUserEnrolled, setUserEnrollments } = useUserEnrollments();
  const { setUserEntitled } = useUserEntitlements();

  useEffect(() => {
    if (userEntitlements && userEntitlements.length > 0) {
      const found =
        userEntitlements?.some(
          ({ courseUuid: userEntitledCourseUuid }) =>
            userEntitledCourseUuid === course.activeCourseRun?.uuid,
        ) || false;
      setUserEntitled(found);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEntitlements]);

  //set context enrollmment status
  useEffect(() => {
    if (userEnrollments && userEnrollments.length > 0) {
      setUserEnrollments(userEnrollments);
      const courseIds = course.activeCourseRuns?.map(({ key }) => key) || [];
      const isEnrolled = isUserEnrolled(userEnrollments, courseIds);
      setIsUserEnrolled(isEnrolled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEnrollments]);

  const {
    syllabusHtml,
    productFullDescription,
    video,
    productImageUrl,
    customizations,
  } = course;
  const { catalogPage } = customizations;
  const {
    weeksToComplete,
    minEffort,
    maxEffort,
    languageId,
    transcriptLanguages,
    seats,
  } = course.activeCourseRun || {};
  const courseHeaderVideo = {
    image: {
      src: productImageUrl,
    },
    src: video.src,
  };
  const courseTitle = `${course.partners.map((obj) => obj.key).join(", ")}: ${course.productName}`;
  const isArchived = course.activeCourseRun
    ? isCourseArchived(course.activeCourseRun)
    : true;
  const formattedLanguage = languageId
    ? ISO6391.getNativeName(languageId.slice(0, 2))
    : undefined;
  const formattedTranscripts =
    transcriptLanguages?.map((transcript) =>
      ISO6391.getNativeName(transcript.slice(0, 2)),
    ) || [];
  const leadWithPaidData = useLeadWithPaid(course);

  const {
    emphasisModuleVersion,
    showRedesignedTrackComparison,
    information,
    stats,
    testimonial,
    tableDescription,
    tabsOrder,
  } = useCertificateEmphasisRedesign();

  type HeaderBackground = "control" | "light" | "green";
  const BACKGROUND_CLASSES = {
    control: styles.contentHero,
    light: "bg-putty-200",
    green: "bg-secondary-500",
  };

  const { headerBackground, sectionsOrder, isLoading, factoidsBar } =
    useSingleCourseDetailPageRedesign();

  const getBackgroundClass = (bg?: HeaderBackground | undefined): string => {
    return bg
      ? (BACKGROUND_CLASSES[bg] ?? styles.contentHero)
      : styles.contentHero;
  };
  const breadcrumbVariant = headerBackground === "light" ? "dark" : "light";

  const componentOrder = [
    { key: "whatYoullLearn", order: sectionsOrder?.whatYoullLearn ?? 1 },
    { key: "courseAbout", order: sectionsOrder?.courseAbout ?? 2 },
    {
      key: "emphasisModuleVersion",
      order: sectionsOrder?.emphasisModuleVersion ?? 3,
    },
    {
      key: "aceRecommendationCard",
      order: sectionsOrder?.aceRecommendationCard ?? 4,
    },
    { key: "curriculum", order: sectionsOrder?.curriculum ?? 5 },
    { key: "courseAwards", order: sectionsOrder?.courseAwards ?? 6 },
    { key: "midPageCta", order: sectionsOrder?.midPageCta ?? 7 },
    { key: "fullProgramCta", order: sectionsOrder?.fullProgramCta ?? 8 },
    {
      key: "learnerTestimonials",
      order: sectionsOrder?.learnerTestimonials ?? 9,
    },
    { key: "instructorList", order: sectionsOrder?.instructorList ?? 10 },
    { key: "faqs", order: sectionsOrder?.faqs ?? 11 },
    { key: "waysToTakeCourse", order: sectionsOrder?.waysToTakeCourse ?? 12 },
    { key: "moreAboutCourse", order: sectionsOrder?.moreAboutCourse ?? 13 },
    { key: "socialShare", order: sectionsOrder?.socialShare ?? 14 },
    { key: "enterpriseCta", order: sectionsOrder?.enterpriseCta ?? 15 },
  ];
  const sortedComponents = componentOrder.sort((a, b) => a.order - b.order);

  return (
    <>
      <div
        className={`${getBackgroundClass(headerBackground as HeaderBackground)} pb-12 pt-20 sm:py-12`}
      >
        <div className={`${maxWidthContainer} ${fullWidthContainer} gap-8`}>
          <div className="w-full flex justify-start">
            <Breadcrumbs
              path={course.productUrlSlug || ""}
              pageName={courseTitle || ""}
              linksToShowOnSmallScreen={1}
              containerClassName="not-prose"
              variant={breadcrumbVariant}
            />
          </div>
          <div className="w-full">
            <CourseHeader title={courseTitle} course={course} />
          </div>

          <div className="w-full">
            <FactoidBar
              courseData={course}
              paymentOption={paymentOption}
              leadWithPaidData={leadWithPaidData}
              isFactoidsBarFieldsLoading={isLoading}
              monarchFactoidsBar={factoidsBar}
            />
          </div>
        </div>
      </div>
      {course.activeCourseRun && (
        <StickyCTA course={course} courseRun={course.activeCourseRun} />
      )}
      <div className="bg-putty-100 pt-12 md:pt-16 flex flex-col gap-12 sm:gap-16">
        <div className={mainContent}>
          {sortedComponents.map(({ key }) => {
            switch (key) {
              case "whatYoullLearn":
                return (
                  <WhatYoullLearn
                    key="whatYoullLearn"
                    title="What you'll learn"
                    parseContent={course.productOverview}
                    associatedSkills={course.associatedSkills}
                    prerequisitesHtml={course.prerequisitesHtml}
                    syllabusHtml={syllabusHtml}
                    formattedLanguage={formattedLanguage}
                    formattedTranscripts={formattedTranscripts}
                  />
                );
              case "courseAbout":
                return (
                  <CourseAbout
                    key="courseAbout"
                    courseData={course}
                    fullDescription={productFullDescription}
                    video={courseHeaderVideo}
                  />
                );

              case "emphasisModuleVersion":
                return showCourseModes &&
                  !isArchived &&
                  course?.activeCourseRun?.isEnrollable &&
                  emphasisModuleVersion &&
                  emphasisModuleVersion !== RedesignedTrackComparison.Hidden ? (
                  <div className={maxWidthContainer}>
                    {emphasisModuleVersion ===
                    RedesignedTrackComparison.Static ? (
                      <CertificateEmphasis
                        key="certificateEmphasis"
                        course={course}
                        courseRun={course?.activeCourseRun}
                        information={information}
                        stats={stats}
                        testimonial={testimonial}
                      />
                    ) : (
                      <CertificateEmphasisTabs
                        key="certificateEmphasisTabs"
                        course={course}
                        courseRun={course?.activeCourseRun}
                        information={information}
                        stats={stats}
                        testimonial={testimonial}
                        tabOrder={tabsOrder}
                      />
                    )}
                  </div>
                ) : null;
              case "aceRecommendationCard":
                return catalogPage?.aceLink ? (
                  <div id="ace">
                    <ACERecommendationCard
                      key="aceRecommendationCard"
                      aceLink={catalogPage?.aceLink}
                    />
                  </div>
                ) : null;
              case "curriculum":
                return syllabusHtml ? (
                  <Curriculum
                    key="curriculum"
                    syllabus={syllabusHtml}
                    weeksToComplete={weeksToComplete}
                    minEffort={minEffort}
                    maxEffort={maxEffort}
                    formattedLanguage={formattedLanguage}
                    formattedTranscripts={formattedTranscripts}
                  />
                ) : null;
              case "couserAwards":
                return course.customizations.catalogPage?.awards?.length ? (
                  <CourseAwards
                    key="courseAwards"
                    awards={course.customizations.catalogPage.awards}
                    isSpanish={false}
                  />
                ) : null;
              case "midPageCta":
                return (
                  <MidPageCTA
                    key="midPageCta"
                    course={course}
                    isArchived={isArchived}
                  />
                );
              case "fullProgramCta":
                return course.programs && course.programs.length > 0 ? (
                  <div className={`${maxWidthContainer} w-full bg-putty-400 `}>
                    <FullProgramCTA
                      key="fullProgramCta"
                      items={course.programs}
                    />
                  </div>
                ) : null;
              case "learnerTestimonials":
                return course.learnerTestimonials ? (
                  <LearnerTestimonials
                    key="learnerTestimonials"
                    testimonials={course.learnerTestimonials}
                  />
                ) : null;
              case "instructorList":
                return course.activeCourseRun?.instructors &&
                  course.activeCourseRun?.instructors.length > 0 ? (
                  <InstructorList
                    key="instructorList"
                    instructors={course.activeCourseRun?.instructors}
                  />
                ) : null;
              case "faqs":
                return <FAQs key="faqs" course={course} />;
              case "waysToTakeCourse":
                return showCourseModes &&
                  !isArchived &&
                  course?.activeCourseRun?.isEnrollable ? (
                  showRedesignedTrackComparison ? (
                    <div className={maxWidthContainer}>
                      <CertificateWaysToTakeCourse
                        key="certificateWaysToTakeCourse"
                        activeCourseRun={course.activeCourseRun}
                        tableDescription={tableDescription}
                      >
                        <div className="flex flex-col md:flex-row gap-2 sm:gap-3 items-center">
                          <EnrollLink
                            buttonClass="flex-1"
                            courseRun={course.activeCourseRun}
                            course={course}
                          />
                          <AuditLink
                            courseRun={course.activeCourseRun}
                            course={course}
                            className="flex-1"
                          />
                        </div>
                      </CertificateWaysToTakeCourse>
                    </div>
                  ) : (
                    <div className={mainContent}>
                      <WaysToTakeCourse
                        key="waysToTakeCourse"
                        activeCourseRun={course.activeCourseRun}
                        course={course}
                      />
                    </div>
                  )
                ) : null;
              case "moreAboutCourse":
                return course.additionalInformation &&
                  course.frequentlyAskedQuestions ? (
                  <MoreAboutCourse
                    key="moreAboutCourse"
                    additionalInformation={course.additionalInformation}
                  />
                ) : null;
              case "socialShare":
                return (
                  <SocialShare
                    key="socialShare"
                    title="Share this course"
                    productUrl={course.productUrl}
                    productName={course.productName}
                  />
                );
              case "enterpriseCta":
                return (
                  <div
                    className="bg-putty-300"
                    style={{
                      maxWidth: "none",
                      width: "100vw",
                      marginLeft: "calc(-50vw + 50%)",
                    }}
                  >
                    <EnterpriseCTA
                      key="enterpriseCta"
                      seats={seats ?? []}
                      courseName={courseTitle}
                      isArchived={isArchived}
                    />
                  </div>
                );
              default:
                null;
            }
          })}
        </div>
      </div>
    </>
  );
}
