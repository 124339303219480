import React, { useRef, useState, useEffect, useCallback } from "react";

interface ExpandableTextByLinesProps {
  text: string | JSX.Element | JSX.Element[];
  maxLines?: 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  className?: string;
  showMoreLabel?: string;
  showLessLabel?: string;
}

function ExpandableTextByLines({
  text,
  maxLines = 3,
  className = "",
  showMoreLabel = "Show more",
  showLessLabel = "Show less",
}: ExpandableTextByLinesProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  const checkTruncation = useCallback(() => {
    if (textRef.current) {
      const el = textRef.current;
      const computedStyle = window.getComputedStyle(el);
      const webkitLineClamp =
        computedStyle.getPropertyValue("-webkit-line-clamp");
      const overflow = computedStyle.overflow;
      const isClamped =
        parseInt(webkitLineClamp) > 0 &&
        overflow === "hidden" &&
        el.scrollHeight > el.clientHeight;
      setIsTruncated(isClamped);
    }
  }, []);

  useEffect(() => {
    checkTruncation();
    window.addEventListener("resize", checkTruncation);
    return () => window.removeEventListener("resize", checkTruncation);
  }, [text, maxLines, checkTruncation]);

  return (
    <div className={className}>
      <div
        ref={textRef}
        data-testid="expandable-text-content"
        className={`
          ${isExpanded ? "" : `line-clamp-${maxLines}`}
          transition-all duration-300 ease-in-out
        `}
      >
        {text}
      </div>
      {isTruncated && (
        <button
          data-testid="toggle-button"
          onClick={() => setIsExpanded(!isExpanded)}
          className="mt-2 text-sm underline underline-offset-2"
        >
          {isExpanded ? showLessLabel : showMoreLabel}
        </button>
      )}
    </div>
  );
}

export default ExpandableTextByLines;
