"use client";
import React from "react";
import { isDateInPast } from "@/lib/date";
import { Skeleton } from "@/components/ui/skeleton";

import { cn } from "@/lib/utils";
import { getCourseRunStartText } from "@/lib/course/utils/getCourseRunTilePrimaryText";

import Icon from "@/components/icons";
import { CourseEnrollProps } from "@/components/CourseContent/EnrollButton/types";
import useCourseEnrollment from "../hooks/useCourseEnrollment";
import { MIN_ENROLLMENT_COUNT_FOR_DISPLAY } from "@/components/CourseContent/CourseRunSelector/constants";
import EnrollLink from "../CourseEnrollButtons/EnrollLink";
import AuditLink from "../CourseEnrollButtons/AuditLink";
import MultipleStartDateModal from "@/components/CourseContent/ContentRefresh/MultipleStartDateModal";

function CourseEnrollBox({
  buttonClass = "",
  className = "",
  courseRun,
  course,
  seeMoreDatesHandler,
  name = "",
  location = "",
}: CourseEnrollProps) {
  const {
    isUserEnrolledInRun,
    isCourseEnrollable,
    isPaidLeadLoading,
    isCourseArchived,
  } = useCourseEnrollment({
    courseRun,
    course,
  });
  const enrollmentCount = course.enrollmentCount;

  const showEnrollmentCount =
    !isCourseArchived &&
    courseRun.isEnrollable &&
    enrollmentCount &&
    enrollmentCount >= MIN_ENROLLMENT_COUNT_FOR_DISPLAY;

  if (isPaidLeadLoading) {
    return (
      <div className="flex flex-row gap-3 items-center bg-putty-100 p-4 rounded-xl border-putty-400 border">
        <Skeleton role="status" className="h-11 w-56 rounded-none" />
      </div>
    );
  }
  //
  const enrollmentEnded = courseRun.enrollmentEnd
    ? isDateInPast(courseRun.enrollmentEnd)
    : false;

  const isRetiringSoon = course.customizations?.catalogPage?.retiringSoon;
  const retiringSoonText =
    course.customizations?.catalogPage?.retiringSoonTextOverwrite ||
    "This course is retiring soon";

  return (
    <div className="flex flex-col gap-4">
      {isRetiringSoon && (
        <div className="flex md:justify-end gap-2 text-primary">
          <Icon type="warning" />
          <p className="m-0 text-black">{retiringSoonText}</p>
        </div>
      )}
      <div
        className={cn(
          "flex flex-col gap-4 items-center justify-between bg-putty-100 p-4 rounded-xl border-putty-400 border w-auto",
          className || "",
        )}
      >
        {isCourseArchived || !isCourseEnrollable ? (
          <span className="font-bold text-base">This course is archived</span>
        ) : (
          <div className="text-center">
            {seeMoreDatesHandler ? <div>Next course starts</div> : null}
            <div className="text-lg font-bold">
              {getCourseRunStartText(courseRun, !!seeMoreDatesHandler)}
            </div>
          </div>
        )}

        {isCourseEnrollable ? (
          <div className="flex items-center">
            <EnrollLink
              buttonClass={buttonClass}
              courseRun={courseRun}
              course={course}
              name={name}
              location={location}
            />
          </div>
        ) : !isUserEnrolledInRun && enrollmentEnded ? (
          <span>Future dates to be announced</span>
        ) : null}

        {showEnrollmentCount ? (
          <div className="flex items-center text-base">
            <span className="font-bold m-0">
              {enrollmentCount.toLocaleString()}
            </span>
            <span className="m-0">&nbsp;learners enrolled</span>
          </div>
        ) : null}

        {seeMoreDatesHandler ? (
          <MultipleStartDateModal
            course={course}
            showEnrollmentCount={showEnrollmentCount}
          >
            <div className="flex flex-row gap-1 justify-end items-center">
              <Icon type="calendar-checked" />
              <button className="underline underline-offset-2">
                See more dates
              </button>
            </div>
          </MultipleStartDateModal>
        ) : null}
      </div>
      {isCourseEnrollable ? (
        <AuditLink
          courseRun={courseRun}
          course={course}
          className="pb-1"
          variant="icon"
          name={name}
          location={location}
        />
      ) : null}
    </div>
  );
}

export default CourseEnrollBox;
