"use client";
import React from "react";
import { cn } from "@/lib/utils";
import { enrollButtonBaseStyles } from "../../constants";
import { AuditLinkVaraintProps } from "../types";

const baseStyles = `${enrollButtonBaseStyles} bg-white text-brand border-2 border-brand hover:bg-brand hover:text-white`;

function AuditLinkButton({
  auditCourseLabel,
  isDisabled,
  onEnroll,
  className = "",
}: AuditLinkVaraintProps) {
  return (
    <button
      className={cn(baseStyles, className)}
      onClick={onEnroll}
      disabled={isDisabled}
    >
      {auditCourseLabel}
    </button>
  );
}

export default AuditLinkButton;
