"use client";
import React, { useState } from "react";
import { CourseAuditLinkProps } from "@/components/CourseContent/EnrollButton/types";

import {
  ElementType,
  ElementClickedWebElement,
} from "@/constants/tagular/main";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import useCourseEnrollment from "../../hooks/useCourseEnrollment";
import AuditLinkButton from "./variants/AuditLinkButton";
import AuditLinkIcon from "./variants/AuditLinkIcon";
import AuditLinkAnchor from "./variants/AuditLinkAnchor";

type AuditLinkProps = CourseAuditLinkProps & {
  variant?: "anchor" | "button" | "icon";
};

function AuditLink({
  className = "",
  courseRun,
  course,
  name = "",
  location = "",
  variant = "button",
}: AuditLinkProps) {
  const [isDisabled, setDisabled] = useState(false);
  const {
    isLoggedIn,
    isUserEnrolledInRun,
    isCourseEnrollable,
    handleEnrollment,
    paidLeadValues,
    isCourseArchived,
  } = useCourseEnrollment({
    courseRun,
    course,
  });
  //TODO: Replace with real API DATA
  const isEnterpriseUser = false;

  const { auditCourseLabel } = paidLeadValues || {};

  const sharedElementProps = {
    elementType: ElementType.Button,
    location,
    htmlId: "audit-cta",
    text: "Audit course",
    position: "2",
  };

  const webElement: ElementClickedWebElement = {
    ...sharedElementProps,
    name,
  };

  const certWebElement: ElementClickedWebElement = {
    name: "course-conversion",
  };

  const { elementClicked } = useElementEvents({
    webElement,
  });

  const { elementClicked: certElementClicked } = useElementEvents({
    webElement: certWebElement,
  });

  const onEnroll = () => {
    if (!isDisabled) {
      setDisabled(true);
      location === "cert-module"
        ? certElementClicked({ webElement: certWebElement })
        : elementClicked({ webElement });
      if (isEnterpriseUser) {
        //TODO: OPEN ENTERPRISE MODEL
      } else {
        handleEnrollment("audit-cta");
      }
    }
  };

  const auditCourse = isCourseArchived
    ? null
    : (() => {
        switch (variant) {
          case "button":
            return (
              <AuditLinkButton
                onEnroll={onEnroll}
                auditCourseLabel={auditCourseLabel}
                isDisabled={isDisabled}
                className={className}
              />
            );
          case "icon":
            return (
              <AuditLinkIcon
                onEnroll={onEnroll}
                auditCourseLabel={auditCourseLabel}
                isDisabled={isDisabled}
                className={className}
              />
            );
          case "anchor":
            return (
              <AuditLinkAnchor
                onEnroll={onEnroll}
                auditCourseLabel={auditCourseLabel}
                isDisabled={isDisabled}
                className={className}
              />
            );
          default:
            return (
              <AuditLinkButton
                onEnroll={onEnroll}
                auditCourseLabel={auditCourseLabel}
                isDisabled={isDisabled}
                className={className}
              />
            );
        }
      })();

  return (
    <>
      {!isLoggedIn &&
        isCourseEnrollable &&
        (auditCourseLabel ? auditCourse : null)}
      {isLoggedIn &&
        !isUserEnrolledInRun &&
        isCourseEnrollable &&
        (auditCourseLabel ? auditCourse : null)}
    </>
  );
}

export default AuditLink;
