import EnrollLink from "@/components/CourseContent/ContentRefresh/CourseEnroll/CourseEnrollButtons/EnrollLink";
import { ProcessedCourse } from "@/lib/course/types";
import { useEffect, useState } from "react";
import { CourseRun } from "@/lib/courseRun/types";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogClose,
} from "@/components/Dialog";
import { getCourseRunStartText } from "@/lib/course/utils/getCourseRunTilePrimaryText";
import { sortCourseRuns } from "@/components/CourseContent/ContentRefresh/CourseRunSelector/utils/sortCourseRuns";

const MultipleStartDateModal = ({
  course,
  showEnrollmentCount = false,
  children,
}: {
  course: ProcessedCourse;
  showEnrollmentCount: boolean | 0 | null;
  children: React.ReactNode;
}) => {
  const [courseRun, setCourseRun] = useState<CourseRun | undefined>(undefined);
  const sortedCourseRuns = sortCourseRuns(course.activeCourseRuns);

  useEffect(() => {
    if (course.activeCourseRuns && course.activeCourseRuns.length > 0) {
      setCourseRun(course.activeCourseRuns[0]);
    }
  }, [course.activeCourseRuns]);

  const setValue = (value: string) => {
    if (course.activeCourseRuns) {
      const run = course.activeCourseRuns.find(
        (courseRun) => courseRun.id === value,
      );
      setCourseRun(run);
    }
  };

  return (
    <>
      <Dialog>
        <DialogTrigger>{children}</DialogTrigger>
        <DialogContent className="p-0 gap-0 bg-putty-200">
          <DialogHeader className="p-8">
            <DialogTitle className="text-4xl font-inter italic !font-black">
              Choose your start date
            </DialogTitle>
          </DialogHeader>
          <div>
            {sortedCourseRuns ? (
              <div className="food-radio-inputs px-8 flex gap-5 flex-col md:flex-row">
                {sortedCourseRuns.map((option) => (
                  <label
                    key={option.id}
                    htmlFor={option.id}
                    className="flex justify-center items-center py-4 px-8 border-2 rounded-xl gap-3 bg-putty-100 cursor-pointer"
                    onClick={() => setValue(option.id)}
                  >
                    <input
                      type="radio"
                      id={option.id}
                      name="courseRun"
                      value={option.id}
                      checked={courseRun?.id === option.id}
                      onChange={(e) => setValue(e.target.value)}
                      className="w-5 h-5 border-2 border-[#424242]"
                    />
                    <span className="font-semibold text-md">
                      {`${getCourseRunStartText(option, true)}`}
                    </span>
                  </label>
                ))}
              </div>
            ) : null}
          </div>
          <DialogFooter className="flex flex-col-reverse justify-center items-center  md:flex-row  h-32 md:h-24 p-8">
            {showEnrollmentCount ? (
              <div className="flex items-center text-sm mt-6 md:mt-0">
                <span className="font-bold">
                  {course.enrollmentCount.toLocaleString()}
                </span>
                <span className="m-0">&nbsp;learners enrolled</span>
              </div>
            ) : null}
            <DialogClose>
              {courseRun !== undefined ? (
                <EnrollLink courseRun={courseRun} course={course} />
              ) : null}
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MultipleStartDateModal;
