"use client";

import { CourseEnrollProps } from "../../EnrollButton/types";
import CourseEnrollBox from "./variants/CourseEnrollBox";
import CourseEnrollInline from "./variants/CourseEnrollInline";

type CourseEnrollMainProps = CourseEnrollProps & {
  variant?: "box" | "inline";
  name?: string;
  location?: string;
};

export default function CourseEnroll({
  variant = "inline",
  ...props
}: CourseEnrollMainProps) {
  switch (variant) {
    case "box":
      return <CourseEnrollBox {...props} />;
    case "inline":
      return <CourseEnrollInline {...props} />;
    default:
      return <CourseEnrollInline {...props} />;
  }
}
