"use client";
import React, { useState } from "react";
import hasCourseRunStarted from "@/lib/course/utils/hasCourseRunStarted";
import {
  AUTH_DOMAIN,
  LMS_COURSE_DOMAIN,
  LMS_DOMAIN,
} from "@/lib/course/utils/constants";
import { cn } from "@/lib/utils";
import { CourseEnrollLinkProps } from "@/components/CourseContent/EnrollButton/types";
import useCourseEnrollment from "../hooks/useCourseEnrollment";
import { enrollButtonBaseStyles } from "./constants";
import EnterpriseNotificationModal from "@/components/CourseContent/ContentRefresh/EnterpriseNotificationModal";
import {
  ElementType,
  ElementClickedWebElement,
} from "@/constants/tagular/main";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { getEnrollButtonText } from "@/lib/course/utils/getCourseRunEnrollButtonText";

const baseStyles = `${enrollButtonBaseStyles} bg-brand text-white border-2 border-brand hover:bg-white hover:text-brand hover:border-brand`;

function EnrollLink({
  buttonClass = "",
  courseRun,
  course,
  name = "",
  location = "",
}: CourseEnrollLinkProps) {
  const [isDisabled, setDisabled] = useState(false);
  const [isOpenEnterpriseModal, setIsOpenEnterpriseModal] = useState(false);
  const {
    isLoggedIn,
    isUserEnrolledInRun,
    isCourseEnrollable,
    handleEnrollment,
    commerceCoodrinatorUrl,
    triggerConversionEvent,
    paidLeadValues,
    emailOptIn,
  } = useCourseEnrollment({
    courseRun,
    course,
  });

  //TODO: Replace with real API DATA
  const isEnterpriseUser = false;

  const { linkDirectToPaymentPage, enrollCtaLabel } = paidLeadValues || {};
  const authUrl = linkDirectToPaymentPage
    ? commerceCoodrinatorUrl
    : `${AUTH_DOMAIN}/register?course_id=${encodeURIComponent(courseRun.key)}&enrollment_action=enroll&email_opt_in=${emailOptIn}`;

  const courseStarted = courseRun.start
    ? hasCourseRunStarted(courseRun.start)
    : false;
  const courseUrl = courseStarted
    ? `${LMS_COURSE_DOMAIN}/course/${encodeURIComponent(courseRun.key)}/home`
    : `${LMS_DOMAIN}/dashboard`;

  const enrollButtonText = getEnrollButtonText(
    {
      enrollmentStart: courseRun.enrollmentStart,
      enrollmentEnd: courseRun.enrollmentEnd,
      end: courseRun.end,
    },
    isUserEnrolledInRun || false,
  );

  const ctaLabel =
    enrollButtonText === "Earn a certificate" && enrollCtaLabel
      ? enrollCtaLabel
      : undefined;

  const webElementText = ctaLabel ?? enrollButtonText;

  const sharedElementProps = {
    name,
    elementType: ElementType.Button,
    location,
    position: "1",
    text: webElementText,
  };

  /* Enroll button */
  const enrollWebElement: ElementClickedWebElement = {
    ...sharedElementProps,
    htmlId: "advance-free-cta",
  };

  // Specific to the certificate module
  const certWebElement: ElementClickedWebElement = {
    ...sharedElementProps,
    name: "course-conversion",
    htmlId: "earn-cert",
  };

  const { elementClicked: enrollElementClicked } = useElementEvents({
    webElement: enrollWebElement,
  });

  const { elementClicked: certElementClicked } = useElementEvents({
    webElement: certWebElement,
  });

  const fireEnrollEvent = () => {
    location === "cert-module"
      ? certElementClicked({ webElement: certWebElement })
      : enrollElementClicked({ webElement: enrollWebElement });
  };

  // Not logged in, enrollable
  const onClickAuth = () => {
    if (!isDisabled) {
      setDisabled(true);
      fireEnrollEvent();
      triggerConversionEvent();
      window.location.href = authUrl;
    }
  };

  // Logged in, enrollable
  const onClickEnroll = () => {
    if (!isDisabled) {
      setDisabled(true);
      fireEnrollEvent();
      if (isEnterpriseUser) {
        setIsOpenEnterpriseModal(true);
      } else {
        handleEnrollment();
      }
    }
  };

  /* Already enrolled */
  const alreadyEnrolledWebElement: ElementClickedWebElement = {
    ...sharedElementProps,
    htmlId: "enrolled-course",
    text: enrollButtonText,
  };

  const { elementClicked: alreadyEnrolledElementClicked } = useElementEvents({
    webElement: alreadyEnrolledWebElement,
  });

  const onClickAlreadyEnrolled = () => {
    if (!isDisabled) {
      setDisabled(true);
      triggerConversionEvent();
      alreadyEnrolledElementClicked({ webElement: alreadyEnrolledWebElement });
      window.location.href = courseUrl;
    }
  };

  return (
    <>
      {!isLoggedIn && isCourseEnrollable && (
        <button
          type="button"
          className={cn(baseStyles, buttonClass)}
          onClick={onClickAuth}
          disabled={isDisabled}
          id={courseRun.key}
        >
          {webElementText}
        </button>
      )}
      {isLoggedIn && !isUserEnrolledInRun && isCourseEnrollable && (
        <button
          type="button"
          className={cn(baseStyles, buttonClass)}
          onClick={onClickEnroll}
          disabled={isDisabled}
          id={courseRun.key}
        >
          {webElementText}
        </button>
      )}
      {isLoggedIn && isUserEnrolledInRun && (
        <button
          type="button"
          className={cn(baseStyles, buttonClass)}
          onClick={onClickAlreadyEnrolled}
          disabled={isDisabled}
          id={courseRun.key}
        >
          {enrollButtonText}
        </button>
      )}
      <EnterpriseNotificationModal
        closeModal={() => {
          setIsOpenEnterpriseModal(false);
          setDisabled(false);
        }}
        isOpen={isOpenEnterpriseModal}
        onContinue={onClickEnroll}
      />
    </>
  );
}

export default EnrollLink;
