import { ProcessedCourse } from "@/lib/course/types";
import EnrollLink from "../CourseEnroll/CourseEnrollButtons/EnrollLink";
import AuditLink from "../CourseEnroll/CourseEnrollButtons/AuditLink";
import { isDateInPast } from "@/lib/date";
import useCourseEnrollment from "../CourseEnroll/hooks/useCourseEnrollment";
import { getEnrollButtonText } from "@/lib/course/utils/getCourseRunEnrollButtonText";
import { Skeleton } from "@/components/ui/skeleton";

export interface Props {
  course: ProcessedCourse;
}

export default function MidPageCTAEnrollment({ course }: Props) {
  const { activeCourseRun } = course || {};
  const { isEnrollable } = activeCourseRun || {};
  const name = "mid-page-cta";
  const location = "mid-page";

  const enrollmentEnded = activeCourseRun?.enrollmentEnd
    ? isDateInPast(activeCourseRun.enrollmentEnd)
    : false;

  const { isUserEnrolledInRun, isPaidLeadLoading } = useCourseEnrollment({
    courseRun: activeCourseRun!,
    course,
  });

  if (!activeCourseRun) return;

  const enrollButtonText = getEnrollButtonText(
    {
      enrollmentStart: activeCourseRun.enrollmentStart,
      enrollmentEnd: activeCourseRun.enrollmentEnd,
      end: activeCourseRun.end,
    },
    isUserEnrolledInRun || false,
  );

  if (isPaidLeadLoading) {
    return <Skeleton className="h-11 w-56 rounded-none" />;
  }

  return (
    <>
      {isEnrollable ? (
        <div className="flex flex-col md:flex-row gap-2 items-center">
          <EnrollLink
            courseRun={activeCourseRun}
            course={course}
            name={name}
            location={location}
          />
          <AuditLink
            courseRun={activeCourseRun}
            course={course}
            name={name}
            location={location}
          />
        </div>
      ) : !isUserEnrolledInRun && enrollmentEnded ? (
        <span>Future dates to be announced</span>
      ) : null}
    </>
  );
}
